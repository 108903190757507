
//==========  Import modules  ==========//


// import our global modules
@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';


.tagline {

	font-size: $h3;
	color: $color-white;
	margin: $block-margin * 2 0;
	text-align: center;
	

	@media #{$tablet} {
		text-align: left;
		font-size: $h1;
		width: 40%;
	}

}